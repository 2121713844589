
// Temporary prototype for the PassAuth class
// todo bundle etc...
const passAuth = new PassAuth("auth_cookie");
window.onload = () => {
    const loginSubmitButton = document.getElementById("login-submit-button");
    const message = document.getElementById("login-message");
    if (loginSubmitButton) {
        loginSubmitButton.addEventListener("click", async () => {
            try {
                await passAuth.connect();
            } catch (e) {
                if (message) {
                    message.innerText = e.message;
                }else{
                    console.error(e);
                }
            }
        });
    }
    const logoutLink = document.getElementById("logout-link");
    if (logoutLink) {
        logoutLink.addEventListener("click", async () => {
            try {
                console.log("Logging out");
                await passAuth.disconnect();
            }catch (e) {
                console.error(e);
            }
        });
    }
}